import * as ActionTypes from '../ActionTypes';

export const PropertyRequests = (state = {
    isLoading: true,
    errMess: null,
    requests: []
}, action) => {
    switch(action.type) {
      case ActionTypes.SET_PROPERTY_REQUESTS:
        return { ...state, isLoading: false, requests: action.payload };
      default:
        return state;
    }
};