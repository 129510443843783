import React, {useEffect, useState} from "react";
import {Link, NavLink} from "react-router-dom";


import DashboardIco from "../../../images/dashboard.png"
import Home from "../../../images/home.png"

import logo from "../../../images/logo.svg";
import {QUERY_STALE_TIME} from "../../../consts";
import {useAccount} from "wagmi";
import {useAuth} from "../auth/web3Auth";
import {useQuery} from "@tanstack/react-query";
import {fetchUserFunctions} from "../../../api";
import {AddCircleOutline} from "@mui/icons-material";
import {SetFunctionConfigurationModal} from "../Modals/setFunctionConfiguration";

function Sidebar() {
    const {isAuthenticated, authToken} = useAuth()
    const [sidebarCollapse, setSidebarCollapse] = useState(false)
    const [functions, setFunctions] = useState([])
    const [showFunctionModal, setShowFunctionModal] = useState(false)
    const account = useAccount()

    const {isPending, error, data, isFetching, refetch} = useQuery({
        queryKey: [`accountData-${account.address}-functions`],
        staleTime: QUERY_STALE_TIME,
        queryFn: async () => {
            let res = await fetchUserFunctions(account.address, authToken)

            let functionNames = res["functionIds"]
            let mapResponse = functionNames.map(x => {
                return {"functionName": x}
            })


            setFunctions(mapResponse)
            return mapResponse
        },
        enabled: authToken != null,
        initialData: []

    })

    useEffect(() => {
                if (authToken != null) {
            refetch()
        }
        else {
            setFunctions([])
        }

    }, [authToken])

    // useEffect(() => {
    //
    //     if (isAuthenticated && account)  {
    //
    //         fetchUserFunctions(account.address, authToken)
    //     }
    //
    // },[isAuthenticated, account])

    useEffect(() => {
        sidebarFunctionality()
    })

    // const sidebarFunctionality=()=>{
    //   /* eslint-disable */
    //     "use strict";

    //     $("#sidebarToggle, #sidebarToggleTop").on("click", function (e) {
    //         $("body").toggleClass("sidebar-toggled"), $(".sidebar").toggleClass("toggled"), $(".sidebar").hasClass("toggled") && $(".sidebar .collapse").collapse("hide");
    //     }),
    //     $(window).resize(function () {
    //       $(window).width() < 768 && $(".sidebar .collapse").collapse("hide"),
    //       $(window).width() < 480 && !$(".sidebar").hasClass("toggled") && ($("body").addClass("sidebar-toggled"), $(".sidebar").addClass("toggled"), $(".sidebar .collapse").collapse("hide"));
    //         }),
    //         $("body.fixed-nav .sidebar").on("mousewheel DOMMouseScroll wheel", function (e) {
    //             var o;
    //             768 < $(window).width() && ((o = (o = e.originalEvent).wheelDelta || -o.detail), (this.scrollTop += 30 * (o < 0 ? 1 : -1)), e.preventDefault());
    //         }),
    //         $(document).on("scroll", function () {
    //             100 < $(this).scrollTop() ? $(".scroll-to-top").fadeIn() : $(".scroll-to-top").fadeOut();
    //         });

    // }
    const sidebarFunctionality = () => {
        // let sideBtn = document.getElementById('sidebarToggleTop')
        // sideBtn.addEventListener('click',()=>{
        //   setSidebarCollapse(!sidebarCollapse)
        // })
    }


    return (
        <>

            {/* Sidebar */}
            <ul className={sidebarCollapse ?
                "navbar-nav sidebar sidebar-dark accordion zi-1 pb-100 inactive" :
                "navbar-nav sidebar sidebar-dark accordion zi-1 pb-100"}
                id="accordionSidebar">
                {/* Sidebar - Brand */}
                <Link className="sidebar-brand d-flex align-items-center" to="https://computenetwork.io">
                    <div className="sidebar-brand-text ">
                        <img src={logo} className="w-100" alt="apollo"/>
                    </div>
                    <div className="mini ">
                        <img src={logo} className="w-100" alt="apollo"/>
                    </div>
                </Link>

                {/* sidebar links */}
                <li className="nav-item my-1 mt-5">
                    <NavLink className="nav-link sidebar-Link fw-500" to="/">
                <span className="icon">
                  <img src={Home} alt=""/>
                </span>
                        <span className="">Home</span></NavLink>
                </li>
                <li className="nav-item">
                    <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                       aria-expanded="true" aria-controls="collapseTwo">
                <span className="icon">
                  <img src={DashboardIco} alt=""/>
                </span>
                        <span className="">Dashboard</span>
                    </a>
                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo">

                        <div className="py-2 collapse-inner border-blue">

                            {functions.length > 0 ? functions.map((x, index) => {
                                    const manageLink = `/dashboard/${x.functionName}/manage`
                                    const monitorLink = `/dashboard/${x.functionName}/monitor`
                                    const collapseId = `collapse_${index}`;
                                    return (
                                        <>
                                            <a className="collapse-item collapsed"
                                               href="#" data-bs-toggle="collapse"
                                               data-bs-target={`#${collapseId}`} // Use the unique collapse ID here
                                               aria-expanded="true"
                                               aria-controls={collapseId} // Use the unique collapse ID here
                                            >
                                                <span className="">{x.functionName}</span>
                                            </a>
                                            <div id={collapseId} className="collapse" aria-labelledby="headingTwo">
                                                <ul>
                                                    <li>
                                                        <Link className="" to={manageLink}>Manage</Link>
                                                    </li>
                                                    <li>
                                                        <Link className="" to={monitorLink}>Monitor</Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </>)

                                }) :
                                (<a className="collapse-item collapsed"
                                    href="#" data-bs-toggle="collapse"
                                    aria-expanded="true">
                                    <span> No functions</span></a>)
                            }


                        </div>
                    </div>
                    <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" onClick={() => {
                        setShowFunctionModal(true)
                    }}>
                    <span className="icon">
                        <AddCircleOutline style={{height: "16px", width: "16px"}}/>
                    </span>
                        <span className="">Create Function</span>
                    </a>
                </li>
                <SetFunctionConfigurationModal show={showFunctionModal} onHide={() => {
                    setShowFunctionModal(false)
                }}/>

                {/* <Link className="collapse-item" to="/dashboard/function/1">Manage</Link>
                  <Link className="collapse-item" to="/dashboard/function/2">Monitor</Link> */}
                {/*<li className="nav-item my-1">*/}
                {/*    <NavLink className="nav-link sidebar-Link fw-500" to="/billing">*/}
                {/*<span className="icon">*/}
                {/*  <img src={Billing} alt=""/>*/}
                {/*</span>*/}
                {/*        <span className="">Billing</span></NavLink>*/}
                {/*</li>*/}
                {/*<li className="nav-item my-1">*/}
                {/*    <NavLink className="nav-link sidebar-Link fw-500" to="/setting">*/}
                {/*<span className="icon">*/}
                {/*  <img src={Settiing} alt=""/>*/}
                {/*</span>*/}
                {/*        <span className="">Setting</span></NavLink>*/}
                {/*</li>*/}

                {/* Nav Item - Pages Collapse Menu */}


            </ul>
            {/* End of Sidebar */
            }

        </>
    )

}

export default Sidebar