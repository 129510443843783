import * as ActionTypes from '../ActionTypes';

export const Properties = (state = {
    isLoading: true,
    errMess: null,
    properties: []
}, action) => {
        switch(action.type) {
            case ActionTypes.LOADING_PROPERTIES:
              return {...state, isLoading:  true}
            case ActionTypes.SET_PROPERTIES:
              return {...state, properties: action.payload, isLoading: false};
            case ActionTypes.ADD_PROPERTY:
              console.log(state.properties.concat([action.payload]))
              return {...state, properties: state.properties.concat([action.payload])};
            default:
                return state;
        }
};