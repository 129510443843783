import {useAccount} from "wagmi";
import {useAuth} from "../auth/web3Auth";
import {useConnectModal} from "@rainbow-me/rainbowkit";
import {useEffect} from "react";



export function BlockchainButton(buttonText, onClick, className) {
    const account = useAccount()
    const {isAuthenticated, authenticate} = useAuth()
    const {openConnectModal} = useConnectModal();

    const getOnClick = (event) => {
        if (account.address) {
            if (!isAuthenticated) {
                return authenticate
            }
            else {

                return onClick.bind(event)
            }
        } else {

            return openConnectModal
        }
    }
    const getButtonText = () => {
        if (account.address) {
            if (!isAuthenticated) {
                return "Authenticate"
            } else {
                return buttonText
            }
        } else {
            return "Connect Wallet"
        }

    }
    return (
        <button
            type="submit"
            onClick={(event) => {
                event.preventDefault()

                getOnClick(event)()
            }}
            className={className}
        >
            {getButtonText()}
        </button>
    )
}