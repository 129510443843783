import React, {useEffect, useState} from 'react';
import {Box, createTheme, Pagination, Stack, Switch, TextField} from '@mui/material';
import {styled} from '@mui/material/styles';

import {Loader} from "../loader";
import Delete from "../../../images/delete.svg";
import './dashboard.css'
import {Modal} from 'react-bootstrap';
import {useAccount, useSendTransaction, useWaitForTransactionReceipt} from "wagmi";
import {parseEther} from "viem";
import {useDropzone} from 'react-dropzone';
import {useCookies} from "react-cookie";
import {QUERY_STALE_TIME} from "../../../consts";
import {useAuth} from "../auth/web3Auth";
import {useQuery} from "@tanstack/react-query";
import {getPaymentWallet, getUserBalance, submitPayment} from "../../../api";
import {BlockchainButton} from "../Button/customButton";
import {ERROR_COMMUNICATING_WITH_THE_SERVER} from "../../../messages";
import {toast, ToastContainer} from "react-toastify";
import {CustomToastWithLink} from "../toastify/customToasts";
import "./dashboard.css";
import {AddCredits} from "../Modals/addCreditModal";
import {SetFunctionConfigurationModal} from "../Modals/setFunctionConfiguration";
import PaymentConfirmation, {PaymentCompleted} from "../Modals/paymentCompleted";
import {Terms} from "../Modals/terms";
import localforage from "localforage";
import {ref} from "yup";



function Home({match}) {
	const [fileData, setFileData] = useState(null);

    const [loader, setLoader] = useState(false)
    const {authToken, isAuthenticated} = useAuth()
    const [show, setShow] = useState(false);
    const account = useAccount()
    const {getRootProps, getInputProps, open, acceptedFiles} = useDropzone({noClick: true, multiple: false})
	const [userTermsAcceptanceStatus, setUserTermsAcceptanceStatus, removeUserTermsAcceptanceStatus] = useState(undefined);


	const theme = createTheme({
        components: {
            MuiTextField: {
                styleOverrides: {
                    root: {
                        '&:hover .MuiOutlinedInput-root': {
                            borderColor: 'red', // Change this to the desired hover color
                        },
                    },
                },
            },
        },
    });
    const [loadCreditError, setLoadCreditError] = useState("")
	const [showFunctionModal, setShowFunctionModal] = useState(false);

    const {isPending, error, data, isFetching, refetch} = useQuery({
        queryKey: [`accountData-${account.address}-userBalance`, authToken],
        staleTime: QUERY_STALE_TIME,
        queryFn: async () => {
            const userBalance = await getUserBalance(account, authToken)

            return userBalance
        },
        enabled: authToken != null
    })

	useEffect(() => {
		localforage.getItem('user_terms_acceptance').then((value) => {
			if (value == null) {
				setUserTermsAcceptanceStatus(false)
			}
			else {
				setUserTermsAcceptanceStatus(value)
			}
		})
	}, []); // Run this effect only once on component mount

	useEffect(() => {

        if (!account.address) {
            setLoadCreditError("Please connect your wallet in order to load credits!")
        } else if (!isAuthenticated) {
            setLoadCreditError("Please Authenticate in order to load credits!")
        } else {
            setLoadCreditError("")
        }
    }, [account, isAuthenticated])

    const handleClose = () => {
        setShow(false)
		refetch()
    };
	const handleShow = () => setShow(true);
	const handleShowFunction = () => setShowFunctionModal(true);
    return(
      <>

            <div className="container-fluid dashboardPage">
                <div className="pagePath">Home {">"} </div>
				<Terms show={userTermsAcceptanceStatus != null && userTermsAcceptanceStatus == false} onHide={(value)=>{
					localforage.setItem('user_terms_acceptance', value)
					setUserTermsAcceptanceStatus(value)
				}}></Terms>
                <ToastContainer></ToastContainer>
                <div className="dashBannerMain my-3">
                    <div className="title">Welcome to the Compute Network</div>
                    <div className="head">MULTI-LAYERED APPROACH</div>
                </div>
				{/*<PaymentConfirmation show={true}></PaymentConfirmation>*/}

                <div className="row">
                    <div className="col-md-6 h-100">
                        <div className="currentCreditMain my-3 box-shodow-blue">
                            <div className="title">Your current credits</div>
                            <div className="price">{data ? "$" + data["user"]["usdBalance"] : "$0"}</div>
                            {/*<div className="title">Remaining balance from previous top-up</div>*/}
                            {/*<div className="percent">77%</div>*/}
                            {/*<div className="percBar"><span></span></div>*/}
                        </div>
                    </div>
                    <div className="col-md-6 h-100">
                        <div className="addCreditMain my-3">
                            <div className="content">
								<div className="addMain" onClick={handleShow}>
									+
								</div>
                                <div className="credit">Add Credits</div>
                            </div>
                        </div>
                    </div>
                </div>

          <div className="dashBannerMain dashBannerMain2 my-3">
            <div className="row ai-center">
              <div className="col-lg-4"></div>
              <div className="col-lg-4">
                  <div className="content text-center">
								<div className="addMain" onClick={()=>{

									setShowFunctionModal(true)
								}}>
									+
								</div>
                    <div className="credit">Create Function</div>
                            </div>
                            <div>
                                {acceptedFiles.map(x => {
                                    return <ul style={{direction: "ltr"}}>{x.name}</ul>
                                })

                                }
                            </div>
                        </div>
                        <div className="col-lg-4">
                <div className="heading">
                  <div className="title">Enter into Compute Network’s world</div>
                  <div className="head">CREATE A <br /> NEW FUNCTION</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /.container-fluid */}
        {loader &&
        <Loader />
        }
		<AddCredits handleClose={handleClose} show={show} />
			{/*<Modal className="WalletModal" show={show} onHide={handleClose} centered>*/}
        	{/*<Modal.Body>*/}
          {/*<div className="addCreModal">*/}
			{/*			<h2 className="mb-3">Add Credits</h2>*/}
			{/*			<div className="creditForm">*/}
          {/*                  <Box component="form"*/}
          {/*                  >*/}
          {/*                      <CustomTextField*/}
          {/*                          style={{width: "100%"}}*/}
          {/*                          error={loadCreditError != ""}*/}
          {/*                          helperText={loadCreditError}*/}
          {/*                          id="custom-text"*/}
          {/*                          value={creditToLoad}*/}
          {/*                          onChange={(e) => {*/}
          {/*                              setCreditToLoad(e.target.value)*/}
          {/*                              e.preventDefault()*/}
          {/*                          }}*/}
          {/*                      />*/}
          {/*                      /!*{true && <span className="error-message">Please enter a valid number</span>}*!/*/}
          {/*                      {BlockchainButton("Pay", (event) => {*/}
          {/*                          // event.preventDefault(); // Prevent default form submission behavior*/}

          {/*                          getPaymentWallet(creditToLoad, authToken).then((res) => {*/}
          {/*                              */}
          {/*                              handleClose()*/}
          {/*                              sendTransactionAsync({*/}
          {/*                                  to: res['paymentAddress'],*/}
          {/*                                  value: parseEther(creditToLoad),*/}
          {/*                              })*/}
          {/*                                  .then((res) => {*/}

          {/*                                      */}
          {/*                                      toast.info(CustomToastWithLink("Tx Hash",`https://etherscan.io/tx/${res}`), {*/}
          {/*                                          className: "custom-toast",*/}
          {/*                                          bodyClassName: "custom-toast-body",*/}
          {/*                                          progressStyle: {background: "#18e1fd", height: "1px"}*/}
          {/*                                      })*/}

          {/*                                  }).catch((e) => {*/}
          {/*                                  */}
          {/*                              })*/}
          {/*                          }).catch((e) => {*/}
          {/*                              */}
          {/*                              setLoadCreditError(ERROR_COMMUNICATING_WITH_THE_SERVER)*/}
          {/*                          })*/}


          {/*                      }, "themeBtn mt-4")}*/}
          {/*                      /!*<button className="" >Pay*!/*/}
          {/*                      /!*</button>*!/*/}
          {/*                  </Box>*/}
			{/*			</div>*/}
			{/*		</div>*/}
			{/*	</Modal.Body>*/}
			{/*</Modal>*/}
		  <SetFunctionConfigurationModal show={showFunctionModal} onHide={()=>{
			  setShowFunctionModal(false)
		  }}/>
      </>
	);
  }
  const Table=({data, page, setPage, noOfUser ,limit})=>{
    // const authedUser = useSelector(s => s.authedUser.authedUser);

    const handleChange = (event, value) => {
        setPage(value);
    };


	return (
		<>
			<div className="card sortby-box br-10 border-transparent mt-3">
				<div className="card-header br-10  bg-white border-transparent py-3 ">
					<div className="table-responsive">
						<table class="table drivers-table">
							<thead>
								<tr>
									<th scope="col" className="text-uppercase">
										Name
									</th>
									<th scope="col" className="">
										Email
									</th>
									<th scope="col" className="">
										Phone
									</th>
									<th scope="col" className="">
										country
									</th>
									<th scope="col" className="">
										DOB
									</th>
									<th scope="col" className="">
										Gender
									</th>
									<th scope="col" className="">
										Email verified
									</th>
									<th scope="col"></th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, key) => (
									<tr key={key}>
										<td scope="row" className="d-flex">
											<div className="table-text-dark ">{item?.name}</div>
										</td>
										<td>
											<span className="table-text-dark w-max-c db">
												{item?.email}
											</span>
										</td>
										<td>
											<span className="table-text-dark w-max-c db">
												{item?.phone}
											</span>
										</td>
										<td>
											<span className="table-text-dark w-max-c db">
												{item?.country}
											</span>
										</td>
										<td>
											<span className="table-text-dark w-max-c db">
												{item?.dob}
											</span>
										</td>
										<td>
											<span className="table-text-dark w-max-c db">
												{item?.gender}
											</span>
										</td>
										<td>
											{item?.emailVerified ? (
												<span className="table-text-dark w-max-c db">
													verified
												</span>
											) : (
												<span className="table-text-dark w-max-c db">
													not verified
												</span>
											)}
										</td>
										<td>
											<div className="mr-5">
												{/* <a href="#" className="mr-3"><img src={edit} alt="edit" /></a> */}
												<a href="#">
													<img src={Delete} alt="Delete" />
												</a>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
					<div className="d-flex justify-content-center">
						<Stack spacing={2}>
							<Pagination
								count={Math.round(noOfUser / limit)}
								page={page}
								onChange={handleChange}
							/>
						</Stack>
					</div>
				</div>
			</div>
		</>
	);
};

const PriceRow = ({ icon, name, price, usd }) => {
	return (
		<div className="priceRow">
			<div className="currency">
				<div className="imgDiv me-2">
					<img src={icon} alt="" />
				</div>
				<div className="name">{name}</div>
			</div>
			<div className="priceM" style={{ textAlign: "right" }}>
				<div className="price">{price}</div>
				<div className="dollor">${usd}</div>
			</div>
		</div>
	);
};

const AntSwitch = styled(Switch)(({ theme }) => ({
	width: 28,
	height: 16,
	padding: 0,
	display: "flex",
	"&:active": {
		"& .MuiSwitch-thumb": {
			width: 15,
		},
		"& .MuiSwitch-switchBase.Mui-checked": {
			transform: "translateX(9px)",
		},
	},
	"& .MuiSwitch-switchBase": {
		padding: 2,
		"&.Mui-checked": {
			transform: "translateX(12px)",
			color: "#fff",
			"& + .MuiSwitch-track": {
				opacity: 1,
				backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
			},
		},
	},
	"& .MuiSwitch-thumb": {
		boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
		width: 12,
		height: 12,
		borderRadius: 6,
		transition: theme.transitions.create(["width"], {
			duration: 200,
		}),
	},
	"& .MuiSwitch-track": {
		borderRadius: 16 / 2,
		opacity: 1,
		backgroundColor:
			theme.palette.mode === "dark"
				? "rgba(255,255,255,.35)"
				: "rgba(0,0,0,.25)",
		boxSizing: "border-box",
	},
}));

export default Home;
