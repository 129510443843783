// useAuthenticate.js
import {createContext, useContext, useEffect, useState} from 'react';
import {useAccount, useAccountEffect, useSignMessage} from 'wagmi';
import localforage from "localforage";
import {QUERY_STALE_TIME, SIGNATURE_TIME_OUT} from "../../../consts";
import {getAuthHeader} from "../../../utils/auth.js";
import {getBalance} from "@wagmi/core";
import {mainnet} from "wagmi/chains";
import {useQuery} from "@tanstack/react-query";
import {config} from "../wagmiConfig";
import {getUserBalance} from "../../../api";


async function verifySignatureWithBackend(signature) {
    // Mock backend verification
    if (signature) {
        return "mock-auth-token";
    }
    return null;
}

export function useAuthenticate() {
    const {data: signMessageData, error: errorSignMessage, isLoading, signMessage, variables} = useSignMessage()
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [signedMessageTimestamp, setSignedMessageTimestamp] = useState(0)
    const [authToken, setAuthToken] = useState(null);
    const [balance, setBalance] = useState(0)
    const [userCredits, setUserCredits] = useState(0)

    const account = useAccount()
    const {isPending, error, data, isFetching, refetch:getAccountBalance} = useQuery({
        queryKey: [`accountData-${account.address}-ethBalance-${account.chainId}`],
        staleTime: QUERY_STALE_TIME,
        queryFn: async () => {
            let res = await getBalance(config, {address: account.address, chainId: account.chainId})
            setBalance(parseFloat(res["formatted"]))
            return res["formatted"]
        },
        enabled: true,
    })
    const {isPending2, error2, userCreditsResponse, isFetching2, refetch:getUserCredits} = useQuery({
        queryKey: [`accountData-${account.address}-userCredits`, authToken],
        staleTime: QUERY_STALE_TIME,
        queryFn: async () => {
            const userBalance = await getUserBalance(account, authToken)
                                    setUserCredits(userBalance["user"]["usdBalance"])
            return userBalance
        },
        enabled: authToken != null
    })



    async function onAccountChanged(account) {
                // Read the old session data
        let key = account.address.toLowerCase()
        let userData = await localforage.getItem(key)

        const userDataParsed = userData ? JSON.parse(userData) : {}
        const now = Math.floor(Date.now() / 1000); // Divide by 1000 to get seconds
        // setUserCredits(0)
        // setBalance(0)
        // get the info on the account
        if (account.address.toLowerCase() == (userDataParsed["account"] || "").toLowerCase() && now - JSON.parse(userData)["signedTimestamp"] < SIGNATURE_TIME_OUT) {
            setSignedMessageTimestamp(userDataParsed["signedTimestamp"])
            setAuthToken(userDataParsed["authCookie"])
            setIsAuthenticated(true)
        } else {
            const message = `Welcome to compute!\nClick to sign in.\nThis request will not trigger a blockchain transaction or cost any gas fees.\nWallet address: ${account.address}\nTimestamp: ${now}`
            setSignedMessageTimestamp(now)
            signMessage({message})
        }

    }

    useEffect(() => {
        // const unwatch = watchAccount(getDefaultConfig({
        //     appName: 'My RainbowKit App',
        //     projectId: '07281d7f22793e532b478efa5193eaf2',
        //     chains: [mainnet, sepolia],
        //     ssr: true, // If your dApp uses server side rendering (SSR)
        // }), {onChange(account, prevAccount) {
        //         //     }})
    }, [])

    useEffect(() => {
        if (account.address != undefined) {
            onAccountChanged({address: account.address})
        }

    }, [account.address])
    useEffect(()=>{
        if (authToken!=undefined){
            getUserCredits()
        }
    }, [authToken])

    useEffect(() => {
        if (signMessageData && account.address && signedMessageTimestamp != 0) {
                        const key = account.address.toLowerCase()
            let authHeader = getAuthHeader(account.address, signedMessageTimestamp, signMessageData)

            const authData = {
                'account': account.address,
                'signedTimestamp': signedMessageTimestamp,
                'authCookie': authHeader
            }
            // Enter the data to the localstorage..
            localforage.setItem(key, JSON.stringify(authData))
            setAuthToken(authHeader)
            setIsAuthenticated(true)

        }

    }, [account, signMessageData])

    useAccountEffect({
        async onConnect(data) {
            // onAccountChanged(data)
        },
        async onDisconnect() {
            setIsAuthenticated(false)
            setSignedMessageTimestamp(0)
            setAuthToken(null)

        },

    })

    const authenticate = async () => {
        // if it's not connected first connect the account and then sign the message
        if (!account) {

        }
        // authenticate.
        else {
            const now = Math.floor(Date.now() / 1000); // Divide by 1000 to get seconds
            const message = `Welcome to compute!\nClick to sign in.\nThis request will not trigger a blockchain transaction or cost any gas fees.\nWallet address: ${account.address}\nTimestamp: ${now}`
            signMessage({message})
            setSignedMessageTimestamp(now)
        }

    };

    return {isAuthenticated, authToken, authenticate, balance, userCredits};
}

// auth-context.js

const AuthContext = createContext();

export const AuthProvider = ({children}) => {
    const auth = useAuthenticate();
    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
};