import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { AuthedUser } from './reducers/authedUser';
import { AuthedAdmin } from './reducers/authedAdmin';
import { Properties } from './reducers/properties';
import { BuyRequests } from './reducers/buyRequests';
import { PropertyRequests } from './reducers/propertyRequests';

const ConfigureStore = () => {
    const store = configureStore({
      reducer: {
        authedUser: AuthedUser,
        authedAdmin: AuthedAdmin,
        properties: Properties,
        requests: BuyRequests,
        propertyRequests: PropertyRequests
      },
      middleware: [thunk, logger]  
    });
    return store;
}

const store = ConfigureStore();

export default store;