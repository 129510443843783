export const LOGGED_IN = 'LOGGED_IN';
export const UPDATE_USER = 'UPDATE_USER';
export const LOGGED_OUT = 'LOGGED_OUT';
export const LOGGED_IN_ADMIN = 'LOGGED_IN_ADMIN';
export const LOGGED_OUT_ADMIN = 'LOGGED_OUT_ADMIN';

export const SET_PROPERTIES = 'SET_PROPERTIES';
export const ADD_PROPERTY = 'ADD_PROPERTY'
export const LOADING_PROPERTIES = 'LOADING_PROPERTIES'

export const SET_REQUESTS = 'SET_REQUESTS';

export const SET_PROPERTY_REQUESTS = 'SET_PROPERTY_REQUESTS';