import axios from "axios";
import {BACKEND_ENDPOINT, BACKEND_USER_ENDPOINT, GET_FUNCTIONS_BY_USER_ENDPOINT} from "./consts";

// TOOD: add auth header here
export function getFunctionInfo(accountAddress, functionName, token) {
    const url = BACKEND_ENDPOINT + "/getFunctionsInfo/"

    return axios
        .get(url, {headers: {'Authorization': `${token}`}})
        .then((res) => {
            return res.data
        })

}

export function getUserBalance(accountAddress, token) {
    const url = BACKEND_USER_ENDPOINT + "/info"

    return axios
        .get(url, {headers: {'Authorization': `${token}`}})
        .then((res) => {
            return res.data
        })

}

export function getPaymentWallet(creditToLoad, token) {
    let url = `https://ly9szfvah3.execute-api.eu-central-1.amazonaws.com/prod/functions/getPaymentLink?ethAmount=${creditToLoad}`
    return axios
        .get(url, {headers: {'Authorization': `${token}`}})
        .then((res) => {
            return res.data
        })

}

export function uploadFile(functionName, fileName, auth, fileData) {
    const url = `https://ly9szfvah3.execute-api.eu-central-1.amazonaws.com/prod/functions/upload?fileName=${fileName}&funcName=${functionName}`;
    const headers = {
        'Authorization': auth,
        'Content-Type': 'text/plain'
    };
    const data = fileData;
    return axios.post(url, data, {headers})


}

export function submitPayment(token, userAddress, txId) {
    const url = 'https://ly9szfvah3.execute-api.eu-central-1.amazonaws.com/prod/functions/submitPayment';
    const headers = {
        'Authorization': token,
        'Content-Type': 'text/plain',
    };
    // TODO: remove the chain!
    const data = {
        user: userAddress,
        txId: txId,
        // sepoliaREMOVE: true
    };

    return axios.post(url, data, {headers});
}

export function fetchAccountData(account, functionName) {

    return axios
        .get(`http://127.0.0.1:5000/user/${account.address}/${functionName}`)
        .then((res) => {
            return res.data
        })

}


export function createNewFunction(authToken, functionName, timeOut, dailyCredits) {
    const url = 'https://ly9szfvah3.execute-api.eu-central-1.amazonaws.com/prod/functions/create'
    const headers = {
        'Authorization': authToken,
        'Content-Type': 'text/plain'
    };

    const data = {
        funcName: functionName,
        timeout: parseInt(timeOut),
        dailyCredits: parseInt(dailyCredits),
    };


    return axios.post(url, data, {headers})
}

export const fetchUserFunctions = async (userAddress, authToken) => {
    let url = GET_FUNCTIONS_BY_USER_ENDPOINT + "?user=" + userAddress


    try {
        const response = await fetch(url, {
            headers: {
                'Authorization': `${authToken}`
            }
        })
        if (!response.ok) {
            throw new Error('Failed to fetch data');
        }
        const jsonData = await response.json();
        return jsonData

    } catch (error) {
        console.error('Error fetching data:', error);
    }
}
