import React,{useEffect, useState} from "react";

// import { loginUser, loginUserReq } from "../../../redux/ActionCreators";
import { useDispatch, useSelector } from 'react-redux';
// import userAction from "../../../redux/users/action";
import { Link, useNavigate } from "react-router-dom";
import Globe from "../../../images/Globe.svg";
import polygonIco from "../../../images/polygonIco.svg";
import copyIco from "../../../images/copyIco.svg";
import metamask from "../../../images/metamask.png";
import walletConnect from "../../../images/walletConnect.png";
import telegram from "../../../images/telegram.png";
import twitter from "../../../images/twitter.png";
import book from "../../../images/book.png";
import capa from "../../../images/capa.png";
import { logoutAuthUser } from "../../../redux/ActionCreators";
import logo from "../../../images/logoIco.png";
import {ConnectButton} from "@rainbow-me/rainbowkit";
import {useAccount} from "wagmi";



export function NavbarUser() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const authedUser = useSelector(state => state.authedUser);
  // const { setUser } = userAction;
    return(
        <>
             {/* Topbar */}
             <nav className="navbar navbar-expand-md topbar mb-4 static-top">

                <a className="logoDiv d-md-none" href="https://computenetwork.io">
                  <img src={logo} className="w-100" alt="compute-network"  />
                </a>

                <div className="socialMain">
                  <a href="https://t.me/Compute_Network" target="_blank" rel="noopener noreferrer">
                    <img src={telegram} alt="" />
                  </a>
                  <a href="https://twitter.com/ComputeNetwork" target="_blank" rel="noopener noreferrer">
                    <img src={twitter} alt="" />
                  </a>
                  <a href="https://computenetwork.gitbook.io/technical-documentation/" target="_blank" rel="noopener noreferrer">
                    <img src={book} alt="" />
                  </a>
                  <a href="https://computenetwork.medium.com/" target="_blank" rel="noopener noreferrer">
                    <img src={capa} alt="" />
                  </a>
                </div>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <i className="fa fa-bars color-blue" />
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul className="navbar-nav me-auto mb-2 d-md-none mb-lg-0">
                    <li className="nav-item">
                      <Link className="nav-link active" aria-current="page" to="/">Home</Link>
                    </li>
                   
                    <li className="nav-item dropdown">
                      <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                        Dashboard
                      </a>
                      <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" >
                        <div className="py-2 collapse-inner border-blue br-12">
                          {/* <h6 className="collapse-header">Custom Components:</h6> */}

                          <a className="collapse-item display-block collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                            <span className="">Function 1</span>
                          </a>
                          <div id="collapseThree" className="collapse" aria-labelledby="headingTwo" >
                            <ul>
                              <li>
                                <Link className="" to="/dashboard/function1/manage">Manage</Link>
                              </li>
                              <li>
                                <Link className="" to="/dashboard/function1/monitor">Monitor</Link>
                              </li>
                            </ul>
                          </div>  
                          <a className="collapse-item display-block collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                            <span className="">Function 2</span>
                          </a>
                          <div id="collapseFour" className="collapse" aria-labelledby="headingTwo" >
                            <ul>
                              <li>
                                <Link className="" to="/dashboard/function1/manage">Manage</Link>
                              </li>
                              <li>
                                <Link className="" to="/dashboard/function1/monitor">Monitor</Link>
                              </li>
                            </ul>
                          </div>  
                          <a className="collapse-item display-block collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive">
                            <span className="">Function 3</span>
                          </a>
                          <div id="collapseFive" className="collapse" aria-labelledby="headingTwo" >
                            <ul>
                              <li>
                                <Link className="" to="/dashboard/function1/manage">Manage</Link>
                              </li>
                              <li>
                                <Link className="" to="/dashboard/function1/monitor">Monitor</Link>
                              </li>
                            </ul>
                          </div>  
                          <a className="collapse-item display-block collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="true" aria-controls="collapseSix">
                            <span className="">Function 4</span>
                          </a>
                          <div id="collapseSix" className="collapse" aria-labelledby="headingTwo" >
                            <ul>
                              <li>
                                <Link className="" to="/dashboard/function1/manage">Manage</Link>
                              </li>
                              <li>
                                <Link className="" to="/dashboard/function1/monitor">Monitor</Link>
                              </li>
                            </ul>
                          </div>  
                          <a className="collapse-item display-block collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="true" aria-controls="collapseSeven">
                            <span className="">Function 5</span>
                          </a>
                          <div id="collapseSeven" className="collapse" aria-labelledby="headingTwo" >
                            <ul>
                              <li>
                                <Link className="" to="/dashboard/function1/manage">Manage</Link>
                              </li>
                              <li>
                                <Link className="" to="/dashboard/function1/monitor">Monitor</Link>
                              </li>
                            </ul>
                          </div>  

                        </div>
                      </div>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/billing">Billing</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/setting">Setting</Link>
                    </li>
                  
                  </ul>
                    <button className="connectWalletBtn ml-2 d-md-none">
                         <span></span> Connect Wallet
                      </button>
                </div>
                <div className="navbar-nav ml-auto d-768-none">
                    <ConnectButton></ConnectButton>
                    {/*<ConnectButton label={'Sign In'} showBalance={"none"} chainStatus={"none"}></ConnectButton>*/}
                      {/*<button className="connectWalletBtn ml-2">*/}
                      {/*   <span></span> Connect Wallet*/}
                      {/*</button>*/}
                  {/* <div className="nav-item dropdown no-arrow">
                    <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <div className="profile-div ml-2">
                        <span className="mr-2 small profile-name">Connect Wallet</span>
                      </div>
                      <div className="img-profile ms-2">
                        <img className="" src={Globe} />
                      </div>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                          <Link className="dropdown-item" to="/">
                            <i className="fas fa-user fa-sm fa-fw me-2 text-gray-400" />
                            Profile
                          </Link>
                          <a className="dropdown-item" href="#">
                            <i className="fas fa-cogs fa-sm fa-fw me-2 text-gray-400" />
                            Settings
                          </a>
                          <a className="dropdown-item" href="#">
                            <i className="fas fa-list fa-sm fa-fw me-2 text-gray-400" />
                            Activity Log
                          </a>
                          <div className="dropdown-divider" />
                          <a className="dropdown-item" href="">
                            <i className="fas fa-sign-out-alt fa-sm fa-fw me-2 text-gray-400" />
                            Logout
                          </a>
                     
                    </div>
                  </div> */}
                </div>
             
              </nav>
              {/* End of Topbar */}

        </>
    )
    
}
