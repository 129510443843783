import {getDefaultConfig} from "@rainbow-me/rainbowkit";
import {mainnet, sepolia} from "wagmi/chains";

export const config = getDefaultConfig({
    appName: 'My RainbowKit App',
    projectId: '07281d7f22793e532b478efa5193eaf2',
    chains: [mainnet, sepolia],
    // chains: [mainnet],
    ssr: true // If your dApp uses server side rendering (SSR)
});
