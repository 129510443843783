import {styled} from "@mui/material/styles";
import {TextField} from "@mui/material";

const CustomTextField =
    styled(TextField)({
        width: '100%',
        '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
                borderColor: '#18e1fd', // Change this to the desired hover color
            },
            '&.Mui-focused fieldset': {
                borderColor: '#18e1fd', // Change this to the desired focused color
            },
        },
        '& .MuiOutlinedInput-input': {
            padding: '.375rem .75rem',
            borderRadius: '.25rem',
        },
    });
export default CustomTextField;
