import React from 'react';
import {Button, Modal} from "react-bootstrap";
import {useAccount} from "wagmi";
import {useAuth} from "../auth/web3Auth";
import {Loader} from "../loader";

const PaymentConfirmation = ({show, handleClose, functionName}) => {
    const account = useAccount()
    const {authToken} = useAuth()
    return (
        <Modal show={show} onHide={handleClose} centered className={"WalletModal"}>
            <Modal.Body>
                <div className="addCreModal">
                    <div style={styles.container}>
                        <svg width="100" height="100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                            <defs>
                                <filter id="blur" x="-50%" y="-50%" width="200%" height="200%">
                                    <feGaussianBlur in="SourceGraphic" stdDeviation="4"/>
                                </filter>
                                <filter id="glow" height="300%" width="300%" x="-75%" y="-75%">
                                    <feMorphology operator="dilate" radius="4" in="SourceAlpha" result="thicken"/>
                                    <feGaussianBlur in="thicken" stdDeviation="10" result="blurred"/>
                                    <feMerge>
                                        <feMergeNode in="blurred"/>
                                        <feMergeNode in="SourceGraphic"/>
                                    </feMerge>
                                </filter>
                            </defs>
                            <circle cx="50" cy="50" r="40" fill="#00BFFF" filter="url(#blur)"/>
                            <circle cx="50" cy="50" r="30" fill="#1E90FF" filter="url(#glow)"/>
                            <circle cx="50" cy="50" r="20" fill="#4169E1"/>
                            <path d="M40 50 l10 10 l20 -20" stroke="#FFF" strokeWidth="8" fill="none"/>
                        </svg>
                        {/*<svg width="100" height="100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">*/}
                        {/*    <circle cx="50" cy="50" r="40" fill="#008080" />*/}
                        {/*    <circle cx="50" cy="50" r="30" fill="#007070" />*/}
                        {/*    <circle cx="50" cy="50" r="20" fill="#006060" />*/}
                        {/*    <path d="M40 50 l10 10 l20 -20" stroke="#FFF" strokeWidth="8" fill="none" />*/}
                        {/*</svg>*/}
                        {/*<h2 style={styles.title}>Great!</h2>*/}
                        <p style={styles.message}>Function {functionName} was created!</p>
                        <div style={styles.textContainer}>


                            {/*<span style={styles.detailsMessage}>Details:</span>*/}
                            {/*<span style={styles.subMessage}>Function Name: XX1</span>*/}
                            {/*<span style={styles.subMessage}>Function Name: XX1</span>*/}
                            {/*<span style={styles.subMessage}>Function Name: XX1</span>*/}
                            {/*<span style={styles.subMessage}>Function Name: XX1</span>*/}
                        </div>
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer style={styles.footer}>
                <Button style={styles.button} variant="none" onClick={handleClose}>Close</Button>
                <Button style={styles.button} variant="none" href={`/dashboard/${functionName}/manage`}>Go to management page</Button>
            </Modal.Footer>
        </Modal>
    );
};

const styles = {
    button: {
        color: "white",
        backgroundImage: "linear-gradient(-30deg, #18e1fd 0%, #037281 100%)",
        fontFamily: "exo"
    },
    footer: {
        borderTopWidth: "0px",
        // borderTopWidth: "3px",
        // borderImage:"linear-gradient(270deg, #18dffb 0%, #0d7381 100%)",
        background: "#16161A"
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // backgroundColor: '#fff',
        padding: '20px',
        // borderRadius: '10px',
        // boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
    },
    icon: {
        backgroundColor: '#FF6F61',
        borderRadius: '50%',
        padding: '10px',
        marginBottom: '10px',
    },
    checkMark: {
        fontSize: '20px',
        color: '#fff',
    },
    title: {
        color: '#FF6F61',
        margin: '0',
    },
    message: {
        fontSize: '18px',
        margin: '5px 0',
    },
    detailsMessage: {
        marginTop: "10px",

    },
    subMessage: {
        fontSize: '15px',
        color: '#888',
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '10px',
    }, underline: {
        display: 'block',
        width: '100%',
        height: '2px',
        backgroundImage: "linear-gradient(270deg, #18dffb 0%, #0d7381 100%)",
        position: 'absolute',
        bottom: '0',
        left: '0',
    },
    // line:{
    //     // width: "%",
    //     position: "absolute",
    //     left: "0",
    //     top: "0",
    //     bottom: "0",
    //     borderRadius: "5px",
    //     backgroundImage: "linear-gradient(270deg, #18dffb 0%, #0d7381 100%)"
    // }

};

export default PaymentConfirmation