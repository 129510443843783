import * as ActionTypes from '../ActionTypes';

export const AuthedUser = (state = {
    isLoading: false,
    errMess: null,
    authedUser: {}
}, action) => {
        switch(action.type) {
            case ActionTypes.LOGGED_IN:
                if(action?.payload?.registerBy == 'web3'){
                    localStorage.setItem(action?.payload?.address?.toLowerCase(), action.payload.token);
                    localStorage.setItem('connected', 'web3');
                }else{
                    localStorage.setItem(action?.payload?.email, action.payload.token);
                    localStorage.setItem('connected', 'email');
                }
                return {...state, isLoading: false, errMess: null, authedUser: action.payload}
            case ActionTypes.UPDATE_USER:
                return {...state, isLoading: false, errMess: null, authedUser: action.payload}
            case ActionTypes.LOGGED_OUT:


                if(action?.payload?.registerBy == 'web3'){
                    localStorage.removeItem(action.payload.address.toLowerCase());
                }else{
                    localStorage.removeItem(action.payload.email);
                }
                localStorage.removeItem('connected');
                return {...state, isLoading: false, errMess: null, authedUser: {}}
            default:
                return state;
        }
};