import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {Area, AreaChart, Bar, BarChart, CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis} from 'recharts';

import {Loader} from "../loader";
import watchIco from "../../../images/watchIco.svg";
import creditIco from "../../../images/creditIco.svg";
import duration from "../../../images/duration.svg";
import cpu from "../../../images/cpu.svg";
import ram from "../../../images/ram.svg";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {useAccount} from "wagmi";
import {QUERY_STALE_TIME} from "../../../consts";
import {getFunctionInfo} from "../../../api";
import {useAuth} from "../auth/web3Auth";
import moment from "moment";

function getTotalExecutionTime(functionData) {
    let sum = 0
    if (functionData.durationOfCodeExecution === undefined) {
        return "0 seconds"
    }
    for (const data of functionData.durationOfCodeExecution || []) {
        sum += data.amount
    }
    if (sum == 0){
        return 0
    }
    else {
        return moment.duration(sum, 'seconds').humanize()

    }
}

function getNumberOfErrors(functionData) {
    let counter = 0
    for (const error of functionData.errorsOverTime || []) {
        if (error.amount > 0) {
            counter += error.amount
        }
    }
    return counter
}

function Dashboard({match}) {
    const [loader, setLoader] = useState(false)
    const [fileData, setFileData] = useState(false);
    const {authToken, isAuthenticated} = useAuth()
    let params = useParams()
    const functionName = params.function
    const queryClient = useQueryClient()
    const account = useAccount()
    const queryCache = queryClient.getQueryCache()
    const [functionData, setFunctionData] = useState({})
    const {isPending, error, data, isFetching} = useQuery({
        queryKey: [`accountData-${account.address}-functionsData`],
        staleTime: QUERY_STALE_TIME,
        queryFn: async () => {

            const functionInfo = await getFunctionInfo(account, functionName, authToken)

            let functionData = functionInfo["functions"
                ].filter(x => x.name === functionName)[0]
            setFunctionData(functionData)
            return functionInfo
        },
        enabled: !!account && authToken != null
    })
    useEffect(() => {
        if (data) {
            let functionData = data["functions"
                ].filter(x => x.name === functionName)[0]
            setFunctionData(functionData)

        }

    }, [data])


    return (
        <>
            {loader || isPending ?
                (<Loader/>) :
                (<div className="container-fluid dashboardPage">
                    <div className="pagePath">Home {">"} monitor</div>

                    <div className="row">
                        <div className="col-lg-5 h-100 mb-4">
                            <div className="chartCard chart1 border-blue box-shodow-blue">
                                <div className="title"><img className="ico" src={creditIco} alt=""/>Credits used over
                                    time
                                </div>
                                {/* chart */}
                                <Chart1 data={functionData.creditsUsedOverTime}></Chart1>
                                {/* chart */}
                                <div className="bar">
                                    <span></span>
                                </div>
                                <div className="creditM">
                                    <div>Credits purchased</div>
                                    <div>Credits used</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 h-100 mb-4">
                            <div className="chartCard chart1">
                                <div className="title">
                                    <img className="ico" src={watchIco} alt=""/>
                                    Calls over time
                                </div>
                                {/*chart */}
                                <Chart2 data={functionData.callsOverTime || []}></Chart2>
                                {/* chart */}
                                <div className="bar">
                                    <span></span>
                                </div>
                                <div className="creditM">
                                    <div>Credits purchased</div>
                                    <div>Credits used</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-xl-3 mb-4">
                            <div className="chartCard">
                                <div className="title"><img className="ico" src={duration} alt=""/>Duration of code
                                    execution
                                </div>
                                {/* chart */}
                                <Chart3 data={functionData.durationOfCodeExecution}/>
                                {/* chart */}
                                <div className="desc">{"Total Execution: " + getTotalExecutionTime(functionData)}</div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-3 mb-4">
                            <div className="chartCard">
                                <div className="title">
                                    <img className="ico" src={error} alt=""/>
                                    Errors over time
                                </div>
                                {/* chart */}
                                <Chart4 data={functionData.errorsOverTime || []}/>

                                {/* chart */}
                                <div className="desc">
                                    Number of errors: <span
                                    className="color-red">{getNumberOfErrors(functionData)}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-3 mb-4">
                            <div className="chartCard">
                                <div className="title"><img className="ico" src={cpu} alt=""/>Average CPU used</div>
                                {/* chart */}
                                <div className="cpuChartMain">
                                    {/* <Chart5 /> */}
                                    <div className="multi-graph margin mx-auto">
                                        <span className="mbText">{functionData.avgPercentCpu24Hour + "%"}</span>

                                        <div className="graph" data-name="CPU"
                                             style={{
                                                 "--percentage": functionData.avgPercentCpu24Hour,
                                                 "--fill": "#18dffb"
                                             }}>
                                        </div>
                                        <div className="graph" data-name=""
                                             style={{
                                                 '--percentage': 100 + functionData.avgPercentCpu24Hour,
                                                 '--fill': '#203e4e'
                                             }}>
                                        </div>
                                    </div>
                                    <div className="rambar1"></div>
                                </div>
                                {/* chart */}
                                <div className="desc">Avg % of CPU used</div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-3 mb-4">
                            <div className="chartCard">
                                <div className="title"><img className="ico" src={ram} alt=""/>Average RAM used
                                </div>
                                {/* chart */}
                                {/*<div className="ramImg">*/}
                                {/*    <Chart5></Chart5>*/}
                                {/*    <div className="mbText">30MB</div>*/}
                                {/*</div>*/}
                                <div className="cpuChartMain">
                                    {/* <Chart5 /> */}
                                    <div className="multi-graph margin mx-auto">
                                        <span className='mbText'>{functionData.avgPercentRam24Hour + " MB"}</span>

                                        <div className="graph" data-name="Angular"
                                             style={{"--percentage": "100", "--fill": "#18dffb"}}>
                                        </div>
                                        <div className="graph" data-name="React"
                                             style={{'--percentage': '100', '--fill': '#203e4e'}}>
                                        </div>
                                    </div>
                                    <div className="rambar1"></div>
                                </div>
                                {/*<div className="rambar"></div>*/}
                                {/* chart */}
                                <div className="desc">Avg MB of RAM used</div>
                            </div>
                        </div>
                    </div>
                </div>)
            }
        </>
    );
}

const Chart1 = ({data}) => {
    return (
        <>
            {/* <ResponsiveContainer width="100%" height="100%"> */}
            <LineChart width={600} height={300} data={data} margin={{top: 5, right: 20, bottom: 5, left: 0}}
                       className="w-100 h-100">
                <CartesianGrid strokeDasharray="3 3" stroke='#535353'/>
                <XAxis dataKey="timestamp" tickFormatter={(timestamp) => {
                    const date = moment.unix(timestamp);

                    // Extract the hour from the Date object
                    const hours = date.hours();
                    return hours
                }}/>
                <YAxis dataKey={"amount"}/>
                <Tooltip labelFormatter={(label) => `${moment.unix(label).toString()}`}
                         formatter={(value) => [`Credits Used: ${value}`]}/>
                <Line type="monotone" dataKey="amount" stroke="#18e1fd" fill="#C5E0B4" stroke-width="2"
                />
                {/*<Area type="monotone" dataKey="amount" stroke="#82ca9d00" fillOpacity={1} fill="url(#colorPv)"/>*/}
            </LineChart>
        </>
    )
}
const Chart2 = ({data}) => {
    return (
        <>
            {/* <ResponsiveContainer width="100%" height="100%"> */}
            <AreaChart
                width={730}
                height={250}
                data={data}
                margin={{top: 10, right: 30, left: 0, bottom: 0}}
                className="w-100 h-100">
                <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#17f1f4" stopOpacity={0.9}/>
                        <stop offset="95%" stopColor="#154270" stopOpacity={0.8}/>
                    </linearGradient>
                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#008e92" stopOpacity={0.9}/>
                        <stop offset="95%" stopColor="#008e92" stopOpacity={0}/>
                    </linearGradient>
                </defs>
                <XAxis dataKey="timestamp" tickFormatter={(timestamp) => {
                    const date = moment.unix(timestamp);

                    // Extract the hour from the Date object
                    const hours = date.hours();
                    return hours
                }}/>
                <YAxis dataKey={"amount"}/>
                <CartesianGrid stroke="#535353" vertical={false}/>
                <Tooltip labelFormatter={(label) => `${moment.unix(label).toString()}`}
                         formatter={(value) => {

                             return [`Calls Used: ${value}`]
                         }} itemStyle={{color: "rgb(24, 225, 253)"}}/>
                {/*<Line type="monotone" dataKey="amount" stroke="#18e1fd" fill="#C5E0B4" stroke-width="2"/>*/}
                <Area type="monotone" dataKey="amount" stroke="#82ca9d00" fillOpacity={1} fill="url(#colorPv)"/>
                <Line
                    type="monotone"
                    dataKey="amount"
                    stroke="#18e1fd"
                    strokeWidth={2}
                    dot={{ r: 5, fill: '#18e1fd' }}
                    activeDot={{ r: 8 }}
                />
                {/*<Area
					type="monotone"
					dataKey="timestamp" stroke="#8884d800" fillOpacity={1} fill="url(#colorUv)"/>*/}
            </AreaChart>
            {/* </ResponsiveContainer> */}
        </>
    )
}
const Chart3 = ({data}) => {

    return (
        <>
            {/* <ResponsiveContainer width="100%" height="100%"> */}
            <AreaChart width={250} height={131.5} data={data}
                       margin={{top: 10, right: 30, left: 0, bottom: 0}} className="w-100 h-100">
                <XAxis
                    dataKey="timestamp"
                    // label={{ value: 'Month', position: 'insideBottomRight', offset: -5 }}
                    tickFormatter={(tick) => {
                        const date = moment.unix(tick);
                        // Extract the hour from the Date object
                        const hours = date.hours();
                        return hours

                    }}
                />
                <YAxis
                    dataKey={"amount"}
                    // label={{ value: 'Value', angle: -90, position: 'insideLeft' }}
                    // tickFormatter={(tick) => `${tick} units`}
                />
                <defs>
                    <linearGradient id="colorUv2" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#17f1f4" stopOpacity={0.8}/>
                        <stop offset="95%" stopColor="#17f1f4" stopOpacity={0}/>
                    </linearGradient>
                </defs>
                <Tooltip labelFormatter={(label) => `${moment.unix(label).toString()}`}
                         formatter={(value) => [`Duration: ${value} seconds`]}/>

                <Area type="monotone" dataKey="amount" stroke="#17f1f4" fillOpacity={1} fill="url(#colorUv2)"/>
            </AreaChart>
            {/* </ResponsiveContainer> */}
        </>
    )
}
const Chart4 = ({data}) => {

    return (
        <>
            {/* <ResponsiveContainer width="100%" height="100%"> */}
            <AreaChart
                width={250}
                height={131.6}
                data={data}
                margin={{top: 10, right: 30, left: 0, bottom: 0}}
                className="w-100 h-100">
                <defs>
                    <linearGradient id="colorUv3" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#fd004c" stopOpacity={0.8}/>
                        <stop offset="95%" stopColor="#fd004c" stopOpacity={0}/>
                    </linearGradient>
                </defs>
                <Tooltip labelFormatter={(label) => `${moment.unix(label).toString()}`}
                         formatter={(value) => [`Errors: ${value}`]}/>
                <XAxis
                    dataKey="timestamp"
                    // label={{ value: 'Month', position: 'insideBottomRight', offset: -5 }}
                    tickFormatter={(tick) => {
                        const date = moment.unix(tick);
                        // Extract the hour from the Date object
                        const hours = date.hours();
                        return hours

                    }}
                />
                <YAxis
                    dataKey={"amount"}
                    // label={{ value: 'Value', angle: -90, position: 'insideLeft' }}
                    // tickFormatter={(tick) => `${tick} units`}
                />
                <Area type="monotone" dataKey="amount" stroke="#fd004c" fillOpacity={1} fill="url(#colorUv3)"/>
            </AreaChart>
            {/* </ResponsiveContainer> */}
        </>
    );
};
const Chart5 = () => {
    const data = [
        {
            name: "Page A",
            uv: 4000,
            pv: 2400,
            amt: 2400,
        },
        {
            name: "Page B",
            uv: 3000,
            pv: 1398,
            amt: 2210,
        },
        {
            name: "Page C",
            uv: 2000,
            pv: 9800,
            amt: 2290,
        },
        {
            name: "Page D",
            uv: 2780,
            pv: 3908,
            amt: 2000,
        },
    ];
    return (
        <>
            {/* <ResponsiveContainer width="100%" height="100%"> */}
            <BarChart
                width={150}
                height={110}
                data={data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
                barSize={35}
                className="w-100 h-100">
                <defs>
                    <linearGradient id="color11" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="50%" stopColor="#18dffb" stopOpacity={0.8}/>
                        <stop offset="90%" stopColor="#1f75e3" stopOpacity={0.8}/>
                    </linearGradient>
                </defs>
                <Tooltip/>
                {/* <Legend /> */}
                {/* <CartesianGrid strokeDasharray="3 3" /> */}
                <Bar
                    dataKey="pv"
                    fill="url(#color11)"
                    background={{fill: "#194a50"}}
                />
            </BarChart>
            {/* </ResponsiveContainer> */}
        </>
    );
};

export default Dashboard;
