import {Component, useState} from 'react';
import {Link, Navigate, Route, Routes} from "react-router-dom";

import './App.css';
// import './assets/css/animate.css';
// import './assets/css/style.css';
import './assets/css/flaticons.css'
import './component/global/global.css'
import "aos/dist/aos.css";
import Sidebar from './component/global/sidebar/sidebar';
import {NavbarUser} from './component/global/navbar/navbar';
import Home from './component/global/home/home';

import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Loader} from './component/global/loader';
import Billing from './component/global/billing/billing';
import Dashboard from './component/global/dashboad/dashboard';
import Manage from './component/global/manage/manage';
import {getDefaultConfig, RainbowKitProvider} from "@rainbow-me/rainbowkit";
import {mainnet, sepolia} from "wagmi/chains";
import '@rainbow-me/rainbowkit/styles.css';
import {WagmiProvider} from "wagmi";

import {QueryClient, QueryClientProvider,} from '@tanstack/react-query'
import {Error404} from "./error404";
import {AuthProvider, useAuth} from "./component/global/auth/web3Auth";

const queryClient = new QueryClient()

const config = getDefaultConfig({
    appName: 'My RainbowKit App',
    projectId: '07281d7f22793e532b478efa5193eaf2',
    chains: [mainnet],
    // chains: [mainnet],
    ssr: true // If your dApp uses server side rendering (SSR)
});


const PrivateRoute = ({ component: Component, ...rest }) => {
    const { isAuthenticated } = useAuth(); // Assuming isAuthenticated is stored in context

    return isAuthenticated ? (
        <Component {...rest} />
    ) : (
        <Navigate to="/" replace />
    );
};


function App() {
    // const { setUser } = userAction;
    const [loader, setLoader] = useState(false)
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>

            <WagmiProvider config={config}>
                <QueryClientProvider client={queryClient}>
                    <RainbowKitProvider>
                        <AuthProvider>

                            <div className="App">

                                <div id="wrapper">
                                    <Sidebar/>
                                    <div id="content-wrapper" className="d-flex flex-column">
                                        <div id="content">
                                            <NavbarUser/>
                                            <Routes>
                                                <Route exact={true} path="/" element={<Home/>}/>
                                                <Route exact={true} path="/billing" element={<Billing/>}/>
                                                <Route exact={true} path="/dashboard/:function/monitor"
                                                       element={<PrivateRoute component={Dashboard} />}/>
                                                <Route exact={true} path="/dashboard/:function/Manage"
                                                       element={<PrivateRoute component={Manage} />}
                                                />

                                                <Route exact={true} element={<Error404/>}></Route>
                                                <Route exact={true} path={"*"} element={<Home/>}></Route>
                                                {/* <Route

                      path="/"
                      element={<Navigate to="/dashboard" replace />}

                  /> */}
                                            </Routes>
                                        </div>
                                    </div>
                                </div>

                                <ToastContainer/>


                                {loader &&
                                    <Loader/>
                                }
                            </div>
                        </AuthProvider>

                    </RainbowKitProvider>
                </QueryClientProvider>
            </WagmiProvider>
        </QueryClientProvider>
    );
}

export default App;
