import {Button, Modal} from "react-bootstrap";
import {Box, IconButton, InputAdornment, TextField} from "@mui/material";
import {BlockchainButton} from "../Button/customButton";
import {createNewFunction, getPaymentWallet, submitPayment, uploadFile} from "../../../api";
import {parseEther} from "viem";
import {toast} from "react-toastify";
import {CustomToastWithLink} from "../toastify/customToasts";
import {ERROR_COMMUNICATING_WITH_THE_SERVER} from "../../../messages";
import React, {useEffect, useState} from "react";
import {styled} from "@mui/material/styles";
import {useAuth} from "../auth/web3Auth";
import {useAccount, useSendTransaction, useWaitForTransactionReceipt} from "wagmi";
import {CircleLoader} from "react-spinners";
import {LoopOutlined} from "@mui/icons-material";
import {makeStyles} from "@mui/styles";
import * as Yup from "yup";
import {useFormik} from "formik";

const CustomTextField = styled(TextField)({
    width: '100%',
    // border: '1px solid #535353',
    '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
            borderColor: '#18e1fd', // Change this to the desired hover color
        },
        '&.Mui-focused fieldset': {
            borderColor: '#18e1fd', // Change this to the desired focused color
        },
    },
});
const useStyles = makeStyles({
    spinningIcon: {
        animation: '$spin 2s linear infinite' // Adjust duration and timing function as needed
    },
    iconStyle: {color: "red"},
    '@keyframes spin': {
        '0%': {
            transform: 'rotate(0deg)',
        },
        '100%': {
            transform: 'rotate(360deg)',
        },
    },
});

const SpiningIcon = () => {
    const classes = useStyles();

    return (
        <IconButton className={classes.spinningIcon}>
            <LoopOutlined style={{color: "#0690a3"}}/>
        </IconButton>
    );
}

export function AddCredits({handleClose, show}) {
    const [creditToLoad, setCreditToLoad] = useState("")
    const {authToken, balance} = useAuth()
    const [loader, setLoader] = useState(false)
    const [functions, setFunctions] = useState([])

    const {data: hash, sendTransaction, sendTransactionAsync} = useSendTransaction()
    const account = useAccount()
    const {isLoading: isConfirming, isSuccess: isConfirmed} =
        useWaitForTransactionReceipt({
            hash
        })

    const ValidationSchema = Yup.object().shape({
        // Define validation rules for each field
        amount: Yup.number().required('Amount is required').min('0.004', 'Minimum balance to credit: 0.004 ETH').typeError('Please enter a valid number')
    });

    const formik = useFormik({
        initialValues: {amount: 0},
        validationSchema: ValidationSchema,
        validateOnMount: false,
        validate: (values) => {
            if (values.amount > balance) {
                return {amount: `You do not have enough ETH! Please add more ETH to your wallet or lower the ETH amount. Your current balance is: ${balance}`}
            }
        },
        onSubmit: async (values) => {
            if (formik.isValid) {
                setLoader(true)
                getPaymentWallet(values.amount, authToken).then((res) => {
                    setLoader(false)
                    sendTransactionAsync({
                        to: res['paymentAddress'],
                        value: parseEther(values.amount.toString())
                    })
                        .then((res) => {
                            toast.info(CustomToastWithLink("Tx Hash", `https://etherscan.io/tx/xx`), {
                                className: "custom-toast",
                                bodyClassName: "custom-toast-body",
                                progressStyle: {background: "#18e1fd", height: "1px"},
                                icon: <SpiningIcon></SpiningIcon>
                            })
                        }).catch((e) => {

                    })
                }).catch((e) => {
                    toast.error(ERROR_COMMUNICATING_WITH_THE_SERVER)
                })

            }
        }
    });

    useEffect(() => {
        if (isConfirmed) {
            submitPayment(authToken, account.address, hash).then((res) => {
                toast.success(CustomToastWithLink("Payment Completed", `https://etherscan.io/tx/${hash}`), {
                    className: "custom-toast",
                    bodyClassName: "custom-toast-body",
                    progressStyle: {background: "#18e1fd", height: "1px"}
                })
            }).catch((e) => {
            })
        }
    }, [isConfirmed])

    return (<Modal className="WalletModal" show={show} onHide={() => {
        handleClose()
    }} centered>
        <Modal.Body>
            <div className="addCreModal">

                <h2 className="mb-3">Add Credits - ETH</h2>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: "absolute",
                    "left": "50%",
                    "top": "50%"
                }}>
                    <div style={{textAlign: 'center'}}>

                        {loader && <CircleLoader color="#0690a3"
                                                 style={{backgroundImage: "linear-gradient(128deg, #012025 0%, #0b0b0b 100%)"}}/>}
                    </div>

                </div>
                <div className="creditForm">
                    <Box component="form">
                        <CustomTextField
                            style={{width: "100%", display: loader == true ? "none" : "flex", background:"linear-gradient(-32deg, #0f0f0f 0%, #1a1a1a 98%, #1a1a1a 100%)"}}
                            error={formik.errors.amount}
                            id="custom-text"
                            InputProps={{
                                endAdornment: <InputAdornment
                                                              position="end">
                                    <div>
                                        <span style={{fontSize:"12px", color:"rgb(125, 125, 125)"}}> {"Balance: "+ balance.toFixed(3) + " ETH" }
                                            <a  style={{color:"#18e1fd", fontWeight:"bold", cursor:"pointer"}} onClick={()=>{
                                            formik.setFieldValue('amount', balance)
                                        }}> Max</a></span>
                                        {/*<Button onClick={() => {*/}
                                        {/*    formik.setFieldValue('amount', balance)*/}
                                        {/*}}>MAX</Button>*/}
                                    </div>
                                    </InputAdornment>
                            }}
                            id="amount"
                            name="amount"
                            // label="Function Name"
                            value={formik.values.amount}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.amount && Boolean(formik.errors.amount)}
                            helperText={formik.touched.amount && formik.errors.amount}
                            value={formik.values.amount}
                            // onChange={(e) => {
                            //     setCreditToLoad(e.target.value)
                            //     e.preventDefault()
                            // }}
                        />
                        {/*{true && <span className="error-message">Please enter a valid number</span>}*/}
                        {BlockchainButton("Pay", (event) => {
                            formik.handleSubmit()
                        }, "themeBtn mt-4")}
                    </Box>
                </div>
            </div>
        </Modal.Body>
    </Modal>)
}
