import React from "react";

export function Error404() {
    return (<>
        <div className="container" style={{
            position: "fixed",
            top: "20%",
            left: "50%",
            width: "100%",
            transform: "translate(-50%,-50%)",
            textAlign: "center"
        }}>
            <span style={{fontSize:"20px", fontWeight:"bold" }}>Oops.. something went wrong try again later!</span>
        </div>
    </>)
}