import {Modal} from "react-bootstrap";
import React, {useCallback, useEffect, useState} from "react";
import {useDropzone} from "react-dropzone";
import {
    MAX_FILE_SIZE,
    MAX_FUNCTIONS_PER_USER,
    MIN_CREDIT_AMOUNT_FOR_CREATING_FUNCTION,
    QUERY_STALE_TIME
} from "../../../consts";
import {ERROR_COMMUNICATING_WITH_THE_SERVER, ERROR_FILE_SIZE} from "../../../messages";
import {Box, Typography} from "@mui/material";
import {createNewFunction, fetchUserFunctions, getUserBalance, uploadFile} from "../../../api";
import {useAuth} from "../auth/web3Auth";
import CustomTextField from "../TextField/formTextField";
import {BlockchainButton} from "../Button/customButton";
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {toast} from "react-toastify";
import {useQuery} from "@tanstack/react-query";
import {useAccount} from "wagmi";
import PaymentConfirmation from "./paymentCompleted"; // For validation schema


export function SetFunctionConfigurationModal({show, onHide}) {
    const account = useAccount()
    const [functions, setFunctions] = useState([])
    const [showForm, setShowForm] = useState(true)
    const [showFormCompleted, setShowFormCompleted] = useState(false)
    const {authToken, userCredits} = useAuth();
    const {isPending, error, data, isFetching, refetch: refetch2} = useQuery({
        queryKey: [`accountData-${account.address}-functions`, authToken],
        staleTime: QUERY_STALE_TIME,
        queryFn: async () => {
            let res = await fetchUserFunctions(account.address, authToken)

            let functionNames = res["functionIds"]
            let mapResponse = functionNames.map(x => {
                return {"functionName": x}
            })
            setFunctions(mapResponse)
            return mapResponse
        },
        enabled: authToken != null,
        // initialData: []
    })
    const {isPending2, error2, userBalance, isFetching2, refetch} = useQuery({
        queryKey: [`accountData-${account.address}-userBalance`, authToken],
        staleTime: QUERY_STALE_TIME,
        queryFn: async () => {
            const userBalance = await getUserBalance(account, authToken)
            formik.setFieldValue('userBalance', userBalance["user"]["usdBalance"])
                        return userBalance
        },
        enabled: authToken != null
    })

    const ValidationSchema = Yup.object().shape({
        // Define validation rules for each field
        functionName: Yup.string().required('Function Name is required'),
        dailyCredit: Yup.number().required('Daily Credit is required').min(0, "Daily Credit must be greater than 0").typeError("Daily Credit must be a number"),
        timeout: Yup.number().required('timeout is required').min(500, "Daily Credit must be greater than 500").typeError("timeout must be a number"),
        file: Yup.mixed().required('File is required'),
        userBalance: Yup.number().min(MIN_CREDIT_AMOUNT_FOR_CREATING_FUNCTION, "User credits must be greater than 10$")
    });


    const [errorMessage, setErrorMessage] = useState('');
    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles.length === 0) {
            return;
        }
        const file = acceptedFiles[0];
        const fileTooLarge = file.size > MAX_FILE_SIZE;
        if (fileTooLarge) {
            formik.setFieldError('file', ERROR_FILE_SIZE);
        } else {
            formik.setFieldValue('file', acceptedFiles[0])
        }
        // Handle the accepted files here
    }, []);
    const onDropRejected = useCallback((fileRejections) => {
        fileRejections.forEach((fileRejection) => {

            fileRejection.errors.forEach((error) => {
                if (error.code === 'file-too-large') {

                    formik.setFieldError('file', ERROR_FILE_SIZE); // Set the file field error
                }
            });
        });
    }, []);
    const {getRootProps, getInputProps, open, acceptedFiles} = useDropzone({
        noClick: true,
        multiple: false,
        onDrop,
        onDropRejected,

        maxSize: MAX_FILE_SIZE,
        accept: {
            'application/zip': ['.zip']
        },
    })
    const initialValues = {
        dailyCredit: null,
        timeout: 500,
        file: '',
        userBalance: userBalance,
        functionName: ''
    }


    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: ValidationSchema,
        validateOnMount: true,
        validate: (values) => {
            const currentFunctionName = values.functionName
            if (functions.length >= MAX_FUNCTIONS_PER_USER) {
                return {
                    functionName: "Maximum number of functions reached!"
                }
            }
            if (currentFunctionName) {
                if ((functions || []).map(x => x.functionName.toLowerCase()).includes(currentFunctionName.toLowerCase())) {
                    return {
                        functionName: "Function name already exists"
                    }
                }
            }

        },
        onSubmit: async (values) => {
            // Handle form submission
            if (formik.isValid && acceptedFiles.length > 0) {
                createNewFunction(authToken, formik.values.functionName, formik.values.timeout, formik.values.dailyCredit).then((res) => {
                    const reader = new FileReader()
                    reader.onabort = () => console.log('file reading was aborted')
                    reader.onerror = () => console.log('file reading has failed')
                    const file = acceptedFiles[0]
                    reader.readAsArrayBuffer(file)
                    reader.onload = () => {
                        // Do whatever you want with the file contents
                        const binaryStr = reader.result
                        uploadFile(formik.values.functionName, file.name, authToken, binaryStr).then(() => {
                            // TODO: refresh data from server.
                            toast.success('Function created successfully!', {
                                className: "custom-toast",
                                bodyClassName: "custom-toast-body",
                                progressStyle: {background: "#18e1fd", height: "1px"}
                            })
                            setShowForm(false)
                            setShowFormCompleted(true)
                            // TODO: Get configuration from the server for the endpoint!
                        }).catch((e) => {
                            toast.error(ERROR_COMMUNICATING_WITH_THE_SERVER, {
                                className: "custom-toast",
                                bodyClassName: "custom-toast-body",
                                progressStyle: {background: "#18e1fd", height: "1px"}
                            })

                            setErrorMessage(ERROR_COMMUNICATING_WITH_THE_SERVER)
                        })
                    }
                })
                    // TODO: handle the error!
                    .catch((e) => {
                        toast.error(ERROR_COMMUNICATING_WITH_THE_SERVER, {
                            className: "custom-toast",
                            bodyClassName: "custom-toast-body",
                            progressStyle: {background: "#18e1fd", height: "1px"}
                        })

                        setErrorMessage(ERROR_COMMUNICATING_WITH_THE_SERVER)

                    })

            }

        },
    });

    useEffect(() => {
        refetch()
        refetch2()
    }, [])
    useEffect(() => {
        }, [functions])
    useEffect(() => {
        formik.setFieldValue('userBalance', userBalance)
    }, [userBalance])
    useEffect(()=>{
            },[userCredits])

    useEffect(() => {
        }, [formik.values, formik.errors])


    return (
        <>
            <Modal
                className="WalletModal"
                show={show && showForm}
                onHide={() => {
                    formik.resetForm({values: initialValues, touched: {}});
                    onHide()
                }}
                centered>
                <Modal.Body>
                    <div className="addCreModal">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="mt-4">
                                <h5 className="mb-3">Function Name</h5>
                                <div className="creditForm">
                                    <Box component="form">
                                        <CustomTextField
                                            // style={{width: "100%", height:"50px"}}
                                            id="functionName"
                                            name="functionName"
                                            // label="Function Name"
                                            value={formik.values.functionName}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.functionName && Boolean(formik.errors.functionName)}
                                            helperText={formik.touched.functionName && formik.errors.functionName}
                                            // onChange={(e) => {
                                            //     e.preventDefault()
                                            //     setFunctionName(e.target.value)
                                            // }}
                                        />
                                    </Box>
                                </div>
                            </div>
                            <div>
                                <div className="mt-4">
                                    <h5 className="mb-3">Timeout</h5>
                                    <div className="creditForm">
                                        <CustomTextField
                                            name="timeout"
                                            id="timeout"
                                            value={formik.values.timeout}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.timeout && Boolean(formik.errors.timeout)}
                                            helperText={formik.touched.timeout && formik.errors.timeout}

                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="mt-4">
                                <h5 className="mb-3">Daily credit cap</h5>
                                <div className="creditForm">
                                    <Box component="form">
                                        <CustomTextField
                                            required
                                            // style={{width: "100%", height:"50px"}}
                                            name="dailyCredit"
                                            id="dailyCredit"
                                            value={formik.values.dailyCredit}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.dailyCredit && Boolean(formik.errors.dailyCredit)}
                                            helperText={formik.touched.dailyCredit && formik.errors.dailyCredit}

                                        />
                                    </Box>
                                </div>
                            </div>
                            <div className="mt-4">
                                <h5 className="mb-3">Upload file</h5>
                                <label className="addMain">
                                    <label htmlFor="btnFile" className="custom-file-upload" onClick={() => {

                                        formik.setFieldValue('file', null)
                                        formik.setFieldTouched('file', true); // Mark the file field as touched
                                        open()
                                    }}>
                                        Choose File
                                    </label>
                                </label>
                                <div className="credit mt-3">
                                    {formik.touched.file &&
                                    formik.values.file == null ? "" : formik.values.file.name}
                                </div>


                            </div>
                            {
                                formik.touched.file && (
                                    <Typography variant="body2" color="error">
                                        {formik.errors.file}
                                    </Typography>
                                )
                            }
                            {
                                formik.errors.userBalance && (
                                    <Typography variant="body2" color="error" style={{marginTop: "10px"}}>
                                        {"Error: " + formik.errors.userBalance}
                                    </Typography>
                                )
                            }
                            {
                                BlockchainButton("Create", (event) => {
                                    formik.handleSubmit()


                                }, "themeBtn mt-4")
                            }

                        </form>
                    </div>
                </Modal.Body>

            </Modal>
            <PaymentConfirmation show={show && showFormCompleted} functionName={formik.values.functionName}
                                 handleClose={() => {
                                     setShowFormCompleted(false)
                                     setShowForm(true)
                                     formik.resetForm({values: initialValues, touched: {}});
                                     onHide()
                                 }}></PaymentConfirmation>
        </>
    )
}