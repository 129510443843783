import React, {useCallback, useEffect, useState} from 'react';
import {Switch, Typography} from '@mui/material';
import {useParams} from "react-router-dom";
import {styled} from '@mui/material/styles';

import {Loader} from '../loader';
import {Modal} from 'react-bootstrap';
import {useQuery} from "@tanstack/react-query";
import {MAX_FILE_SIZE, QUERY_STALE_TIME} from "../../../consts";
import {useAccount} from "wagmi";
import {getFunctionInfo, uploadFile} from "../../../api";
import {useDropzone} from 'react-dropzone';
import {useAuth} from "../auth/web3Auth";
import {ERROR_FILE_SIZE, FILE_UPLOAD_SUCCESS} from "../../../messages";
import {toast, ToastContainer} from "react-toastify";
import "../global.css"
import EditIcon from "../../../images/pencil_blue.svg";
import {BlockchainButton} from "../Button/customButton";
function Manage({ match }) {
	const [fileData, setFileData] = useState(false);
	const [loader, setLoader] = useState(false);
	const [show, setShow] = useState(false);
	const [timeOutField, setTimeoutField] = useState(500);
	const [dailyCreditField, setDailyCredit] = useState(800);
    const [errorMessage, setErrorMessage] = useState('');
    const onDrop = useCallback((acceptedFiles) => {
        setErrorMessage('');

        // Handle the accepted files here
    }, []);

    const onDropRejected = useCallback((fileRejections) => {
        fileRejections.forEach((fileRejection) => {
            fileRejection.errors.forEach((error) => {
                if (error.code === 'file-too-large') {
                    setErrorMessage(ERROR_FILE_SIZE);
                }
            });
        });
    }, []);
    const {getRootProps, getInputProps, open, acceptedFiles} = useDropzone({
        noClick: true,
        multiple: false,
        onDrop,
        onDropRejected,
        maxSize: MAX_FILE_SIZE,
        accept: {
            'application/zip': ['.zip']
        },
    })
    const [functionData, setFunctionData] = useState({})
    const account = useAccount()
    let params = useParams()
    const functionName = params.function
    const {authToken, isAuthenticated} = useAuth()
    const {isPending, error, data, isFetching, refetch} = useQuery({
        queryKey: [`accountData-${account.address}-functionsData`],
        staleTime: QUERY_STALE_TIME,
        queryFn: async () => {

            const functionInfo = await getFunctionInfo(account, functionName.toLowerCase(), authToken)



            return functionInfo
        },
        enabled: !!account && authToken != null
    })


    useEffect(() => {
        if (data) {
            let functionData = data["functions"
                ].filter(x => x.name.toLowerCase() === functionName.toLowerCase())[0]
            setFunctionData(functionData)
        }

    }, [data])

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const [formData, setFormData] = useState(data);

	const onInputChange = (e) => {
		let { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};
	const onSubmitData = () => {
		setDailyCredit(formData.dailyCreditField);
		setTimeoutField(formData.timeOutField);
		handleClose();
	};

    return(
      <>

            {/* Begin Page Content */}

			  <menu></menu>


        <div className="container-fluid dashboardPage">
          <div className="pagePath">Home {">"} manage</div>
                <ToastContainer/>
                <div className="dashBannerMain manageMain my-3">
                    <div className="title">Configuration Option</div>
                    <div className="head">{functionName}</div>
                </div>

                <div className="row">
                    <div className="col-md-6 h-100">
                        <div className="manageForm p-3 my-3">
							<div className="head">
								Configuration

								{/*<img*/}
								{/*	src={EditIcon}*/}
								{/*	alt="Delete"*/}
								{/*	onClick={() => {*/}
								{/*		setDailyCredit(1000);*/}
								{/*		handleShow();*/}
								{/*	}}*/}
								{/*	style={{*/}
								{/*		cursor: "pointer",*/}
								{/*		width: 20,*/}
								{/*		height: 20,*/}
								{/*	}}*/}
								{/*	className="ms-2"*/}
								{/*/>*/}
							</div>
                            <div className="confRow">
                                <div className="name">Endpoint URL</div>
                                <div className="value">
                                    {functionData?.endpointUrl ? functionData.endpointUrl : "In progress.."}
                                </div>
                            </div>
                            <div className="confRow">
                                <div className="name">Status</div>
                                <div className="value">
                    <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                                </div>
                            </div>
                            <div className="confRow">
                                <div className="name">Current File</div>
                                <div className="value">{functionData?.zipUrl ? functionData.zipUrl : ""} </div>
                            </div>
                            <div className="confRow">
                                <div className="name">Timeout</div>
                                <div
                                    className="value">{functionData?.configTimeout ? functionData.configTimeout + " sec" : ""}</div>
                            </div>
                            <div className="confRow">
                                <div className="name">Daily credit cap</div>
                                <div
                                    className="value">{functionData?.configDailyCreditCap ? functionData.configDailyCreditCap + " per day" : ""}</div>
                            </div>
                            <div className="confRow">
                                <div className="name">Monthly credit cap</div>
                                <div
                                    className="value">{functionData?.configMonthlyCreditCap ? functionData.configMonthlyCreditCap + " per month" : ""}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 h-100">
                        <div className="addCreditMain my-3">
                            <div className="content">
                                <label className="addMain" onClick={open}>+
                                    {/*<input type="file" className='file'*/}
                                    {/*       onChange={(e) => setFileData(e.target.files[0])}/>*/}
                                </label>
                                {acceptedFiles.length > 0 ? (<div>
                                        {acceptedFiles.map(x => {
                                            return <ul style={{direction: "ltr"}}>{x.name}</ul>
                                        })

                                        }
                                    </div>) :
                                    <div>
                                        Click Here or Drag your .zip to upload a new version.
                                    </div>}
                                {errorMessage && (
                                    <Typography variant="body2" color="error">
                                        {errorMessage}
                                    </Typography>
                                )}


                  </div>
              </div>
                        <div className='text-center mb-3'>
							{BlockchainButton('Upload', ()=>{
                                acceptedFiles.forEach((file) => {
                                    const reader = new FileReader()
                                    reader.onabort = () => console.log('file reading was aborted')
                                    reader.onerror = () => console.log('file reading has failed')
                                    reader.onload = () => {
                                        // Do whatever you want with the file contents
                                        const binaryStr = reader.result
                                        uploadFile(functionName, file.name, authToken, binaryStr).then(() => {
                                            // TODO: refresh data from server.
                                            refetch().then(r => {
                                                toast.success(FILE_UPLOAD_SUCCESS, {
                                                    className: "custom-toast",
                                                    bodyClassName: "custom-toast-body",

                                                    progressStyle:{background: "#18e1fd", height: "1px"}
                                                })

                                            })
                                        }).catch((e) => {

                                        })
                                    }
                                    reader.readAsArrayBuffer(file)
                                })

                            }, 'themeBtn')}

                            {/*<button className="themeBtn" onClick={(event) => {*/}

                            {/*}}>upload*/}
                            {/*</button>*/}
              </div>
            </div>
          </div>

        </div>
        {/* /.container-fluid */}
        {loader &&
        <Loader />
        }

			<Modal className="WalletModal" show={show} onHide={handleClose} centered>
				<Modal.Body>
					<div className="addCreModal">
						<div>
							<h5 className="mb-3">Change timeout</h5>
							<div className="creditForm">
								<input
									className="form-control"
									type="number"
									name="timeOutField"
									id=""
									placeholder="0"
									onChange={onInputChange}
								/>
							</div>
							<div className="creditForm mt-4">
								<div>
									<h5 className="mb-3">Daily market cap</h5>
									<input
										className="form-control mt-3"
										type="number"
										onChange={onInputChange}
										name="dailyCreditField"
										id=""
										placeholder="0"
										// value={formData.dailyCreditField}
									/>
								</div>
							</div>
							<button className="themeBtn mt-4" onClick={onSubmitData}>
								Submit
							</button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}

const AntSwitch = styled(Switch)(({ theme }) => ({
	width: 28,
	height: 16,
	padding: 0,
	display: "flex",
	border: "1px solid #535353",
	borderRadius: 16 / 2,
	"&:active": {
		"& .MuiSwitch-thumb": {
			width: 15,
		},
		"& .MuiSwitch-switchBase.Mui-checked": {
			transform: "translateX(9px)",
		},
	},
	"& .MuiSwitch-switchBase": {
		padding: 2,
		"&.Mui-checked": {
			transform: "translateX(12px)",
			color: "#fff",
			"& + .MuiSwitch-track": {
				opacity: 1,
				backgroundColor: theme.palette.mode === "dark" ? "#18e1fd" : "#18e1fd",
			},
		},
	},
	"& .MuiSwitch-thumb": {
		boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
		width: 12,
		height: 12,
		borderRadius: 6,
		transition: theme.transitions.create(["width"], {
			duration: 200,
		}),
	},
	"& .MuiSwitch-track": {
		borderRadius: 16 / 2,
		opacity: 1,
		backgroundColor:
			theme.palette.mode === "dark"
				? "rgba(255,255,255,.35)"
				: "rgba(0,0,0,.25)",
		boxSizing: "border-box",
	},
}));

export default Manage;
