import React from 'react'
import Logo from "../../images/architoLogo.svg"
import {CircleLoader} from "react-spinners";

export const Loader = ({}) => {
  return (
    <>
          <div className="seekoLoader">
              <div className="loader">
                  <CircleLoader color="#0690a3" style={{backgroundImage:"linear-gradient(128deg, #012025 0%, #0b0b0b 100%)"}} />
              </div>
          </div>

    </>
  )
}
