import React,{useEffect,useState} from 'react';
import { Link, NavLink } from "react-router-dom";
import axios from "axios";

import { useSelector } from 'react-redux';
import { Pagination, Stack, Switch } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Loader } from '../loader';
import Delete from "../../../images/delete.svg";
import cnLogo from "../../../images/cnIco.png";
import ethIco from "../../../images/ethIco.png";
import { Button, Modal } from 'react-bootstrap';



function Billing({match}) {
  const [loader,setLoader] = useState(false)
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
    return(
      <>
        <div className="container-fluid dashboardPage billingPage">
          <div className="pagePath">Home {">"} Billing</div>                   
          
          <div className="row">
            <div className="col-md-8 h-100">
                <div className="mb-4">
                    <Table />
                </div>
                <div className="mb-4">
                    <TableFuntion />
                </div>
            </div>
            <div className="col-md-4 h-100 ">
              <div className="addCreditMain mb-4 border-blue box-shodow-blue">
                  <div className="content">
                    <div className="addMain" onClick={handleShow}>+</div>
                    <div className="credit">Add Credits</div>
                  </div>
              </div>
              {/*<div className="topupCard">*/}
              {/*  <div className="title">Top up your account with ETH or CN</div>*/}
              {/*  <div className="logoMain">*/}
              {/*      <div className="logoDiv">*/}
              {/*          <img src={cnLogo} alt="" />*/}
              {/*      </div>*/}
              {/*      <div className="logoDiv">*/}
              {/*          <img src={ethIco} alt="" />*/}
              {/*      </div>*/}
              {/*  </div>*/}
              {/*      <div className="desc mb-1">Credit Amount</div>*/}
              {/*      <div className="amount">$ 1000</div>*/}
              {/*      <div className="desc">Learn how to add credits</div>*/}
              {/*      <button className="goDoc">Go to Docs</button>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
        {/* /.container-fluid */}
        {loader && 
        <Loader />
        }

      <Modal className='WalletModal' show={show} onHide={handleClose} centered>
        <Modal.Body>
          <div className="addCreModal">
            <h2 className='mb-3'>Add Credits</h2>
            <div className="creditForm">
              <input className='form-control' type="number" name="" id="" placeholder='0' />
              <button className="themeBtn mt-4">Pay</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      </>
    )
  }
  const Table=({data, page, setPage, noOfUser ,limit})=>{
    // const authedUser = useSelector(s => s.authedUser.authedUser);

    const handleChange = (event, value) => {
      setPage(value);
    };




    return(
      <>
      <div className="table-responsive">
        <table className="table table-striped">
            <thead>
                <tr>
                    <th scope="col"><div className='w-max-c'>Payment Date</div></th>
                    <th scope="col"><div className='w-max-c'>Chain</div></th>
                    <th scope="col"><div className='w-max-c'>Payment Amount</div></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>April 5th, 2024</td>
                    <td>Ethereum ERC20</td>
                    <td>0.1 ETH</td>
                    <td>200</td>
                    <td>
                        <div className="status completed">Completed</div>
                    </td>
                    <td>Download</td>
                </tr>
                <tr>
                    <td>April 5th, 2024</td>
                    <td>Ethereum ERC20</td>
                    <td>0.1 ETH</td>
                    <td>200</td>
                    <td>
                        <div className="status completed">Completed</div>
                    </td>
                    <td>Download</td>
                </tr>
                <tr>
                    <td>April 5th, 2024</td>
                    <td>Ethereum ERC20</td>
                    <td>0.1 ETH</td>
                    <td>200</td>
                    <td>
                        <div className="status completed">Completed</div>
                    </td>
                    <td>Download</td>
                </tr>
            
            </tbody>
            </table>
      </div>

    
      </>
    )
  }
  const TableFuntion=({data, page, setPage, noOfUser ,limit})=>{
    // const authedUser = useSelector(s => s.authedUser.authedUser);

    const handleChange = (event, value) => {
      setPage(value);
    };




    return(
      <>
      <div className="table-responsive">
        <table className="table table-striped">
            <thead>
                <tr>
                    <th scope="col"><div className='w-max-c'> Function <span className='color-blue'>1</span></div></th>
                    <th scope="col"><div className='w-max-c'> Function <span className='color-pink'>2</span></div></th>
                    <th scope="col"><div className='w-max-c'> Function <span className='color-yellow'>3</span></div></th>
                    <th scope="col"><div className='w-max-c'> Function <span className='color-green'>4</span></div></th>
                    <th scope="col"><div className='w-max-c'> Function <span className='color-orange'>5</span></div></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Used <span className='color-blue'>200</span> Credits</td>
                    <td>Used <span className='color-pink'>200</span> Credits</td>
                    <td>Used <span className='color-yellow'>200</span> Credits</td>
                    <td>Used <span className='color-green'>200</span> Credits</td>
                    <td>Used <span className='color-orange'>200</span> Credits</td>
                </tr>
                <tr>
                    <td>Used <span className='color-blue'>200</span> Credits</td>
                    <td>Used <span className='color-pink'>200</span> Credits</td>
                    <td>Used <span className='color-yellow'>200</span> Credits</td>
                    <td>Used <span className='color-green'>200</span> Credits</td>
                    <td>Used <span className='color-orange'>200</span> Credits</td>
                </tr>
                <tr>
                    <td>Used <span className='color-blue'>200</span> Credits</td>
                    <td>Used <span className='color-pink'>200</span> Credits</td>
                    <td>Used <span className='color-yellow'>200</span> Credits</td>
                    <td>Used <span className='color-green'>200</span> Credits</td>
                    <td>Used <span className='color-orange'>200</span> Credits</td>
                </tr>
                <tr>
                    <td>Used <span className='color-blue'>200</span> Credits</td>
                    <td>Used <span className='color-pink'>200</span> Credits</td>
                    <td>Used <span className='color-yellow'>200</span> Credits</td>
                    <td>Used <span className='color-green'>200</span> Credits</td>
                    <td>Used <span className='color-orange'>200</span> Credits</td>
                </tr>
                <tr>
                    <td>Used <span className='color-blue'>200</span> Credits</td>
                    <td>Used <span className='color-pink'>200</span> Credits</td>
                    <td>Used <span className='color-yellow'>200</span> Credits</td>
                    <td>Used <span className='color-green'>200</span> Credits</td>
                    <td>Used <span className='color-orange'>200</span> Credits</td>
                </tr>
            </tbody>
            </table>
      </div>

    
      </>
    )
  }


  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(12px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
      boxSizing: 'border-box',
    },
  }));


  

  export default Billing