import {Modal} from "react-bootstrap";
import React, {useState} from "react";
import Checkbox from '@mui/material/Checkbox';
import {createTheme, ThemeProvider, Typography} from "@mui/material";

export function Terms({show, onHide}) {
    const theme = createTheme({
        components: {
            MuiCheckbox: {
                styleOverrides: {
                    root: {
                        color: 'white', // specify your color here
                        '&$checked': {
                            backgroundColor: 'red', // Change the color here
                        },
                    },
                },
            },
        },
    });
    const [checkboxValue, setCheckboxValue] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    return (
        <Modal className="WalletModal" show={show} onHide={onHide} centered>
        <Modal.Body>
            <div className="addCreModal">
                <h2 className="mb-3">Terms Of Service</h2>
                <ThemeProvider theme={theme}>
                <Checkbox style={{borderColor:"white"}} checked={checkboxValue} onClick={(e)=>{
                    setCheckboxValue(e.target.checked )
                }}/>
                </ThemeProvider>
                <span>I agree to the <a href={"/"}>terms of service</a></span>
                {errorMessage != "" && (
                    <Typography variant="body2" color="error">
                        {errorMessage}
                    </Typography>
                )}
                <Modal.Footer style={{borderTop:"none"}}>
                    <button className="themeBtn mt-4" onClick={()=>{
                        if (checkboxValue == false || checkboxValue == undefined) {
                            setErrorMessage('You must agree to the terms of service to use the app.')
                        }
                        else {
                            onHide(checkboxValue)
                        }
                    }}>
                        I Agree
                    </button>
                </Modal.Footer>
            </div>
        </Modal.Body>
    </Modal>)
}